<template>
  <div>
    <YTabs v-model="activeName" active-line>
      <YTabPane name="wxShare" label="微信分享">
        <el-card>
        <el-form v-loading="loading" ref="form" :model="form" label-width="100px" style="width: 30rem">
          <el-form-item label="分享标题">
            <el-input v-model="form.share_title"></el-input>
            <span style="font-size: 9px;color: #888888">支持替换变量:
              <span @click="copyStr('[用户昵称]')">[用户昵称]</span>
              <span @click="copyStr('[活动名称]')">[活动名称]</span>
            </span>
          </el-form-item>
          <el-form-item label="分享描述">
            <el-input v-model="form.share_desc"></el-input>
            <span style="font-size: 9px;color: #888888">支持替换变量:
              <span @click="copyStr('[用户昵称]')">[用户昵称]</span>
              <span @click="copyStr('[活动名称]')">[活动名称]</span>
            </span>
          </el-form-item>
          <el-form-item label="分享图片">
            <yUploadImg v-model="form.share_pic"></yUploadImg>
          </el-form-item>
          <el-form-item label="海报文案">
            <el-input v-model="form.poster_desc"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="formSubmit">保存</el-button>
          </el-form-item>
        </el-form>
        </el-card>
      </YTabPane>
      <YTabPane name="poster" label="海报配置">
        <el-card>
        <edit_poster @back="posterEditBack" v-if="posterShow" :editForm="posterEditForm"></edit_poster>
        <el-table v-if="!posterShow" :data="poster" border style="width: 100%">
          <el-table-column prop="id" label="编号" width="80"></el-table-column>
          <el-table-column label="背景图">
            <template #default="scope">
              <div class="flex-def flex-cCenter flex-zCenter">
                <el-image fit="contain" :src="scope.row.bg | tomedia" style="height: 4rem;"></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="170">
            <template #default="s">{{s.row.created_at | date}}</template>
          </el-table-column>
          <el-table-column label="修改时间" width="170">
            <template #default="s">{{s.row.updated_at | date}}</template>
          </el-table-column>
          <el-table-column align="center" width="200">
            <template slot="header">
              <el-button @click="posterShow=true" size="small" type="primary">添加海报</el-button>
            </template>
            <template slot-scope="scope">
              <div style="margin-bottom: 1rem"><el-button @click="clearCache(scope.row)" size="mini" type="info">清空缓存</el-button></div>
              <el-button-group>
                <el-button size="mini" @click="handleEditPoster(scope.row)">修改</el-button>
                <el-button size="mini" type="danger" @click="handleDeletePoster(scope.$index, scope.row)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        </el-card>
      </YTabPane>
    </YTabs>
  </div>
</template>

<script>
import yUploadImg from "@/components/y_upload_img";
import edit_poster from "@/view/active/edit/edit_poster";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "edit_share",
  components:{
    YTabPane,
    YTabs,
    yUploadImg,
    edit_poster
  },
  data(){
    return{
      activeName:"wxShare",
      posterShow:false,
      form:{
        share_title:"",
        share_desc:"",
        share_pic:"",
        poster_desc:"",
      },
      poster:[],
      posterEditForm:{
        bg:"",
        qrcode_left:0,
        qrcode_top:0,
        qrcode_size:200,
        avatar_left:0,
        avatar_top:230,
        avatar_size:100,
        nickname_left:0,
        nickname_top:350,
        nickname_size:28,
        nickname_color:"#000000"
      },
      loading:false,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
      this.loadPosterAll()
    }
  },
  methods:{
    copyStr(str){
      this.$copyText(str).then(()=>{
        this.$message.info("复制成功");
      })
    },
    clearCache(item){
      this.$api.active.posterClearCache({
        active_id:this.edit_id,
        id:item.id
      }).then(()=>{
        this.$message.success("操作成功");
      })
    },
    posterEditBack(){
      this.posterShow=false;
      this.loadPosterAll();
      this.posterEditForm = {};
    },
    loadPosterAll(){
      this.$api.active.posterAll({active_id:this.edit_id}).then(res=>{
        this.poster = res;
      })
    },
    load(){
      this.loading = true;
      this.$api.active.one({id:this.edit_id}).then(res=>{
        this.loading = false;
        this.form = res;
      })
    },
    formSubmit() {
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    handleEditPoster(row){
      this.posterEditForm = {
        ...row
      };
      this.posterShow=true;
    },
    handleDeletePoster(e){
      let item = this.poster[e];
      this.$api.active.posterDel({
        active_id:item.active_id,
        id:item.id
      }).then(()=>{
        this.$message.success("操作成功");
        this.loadPosterAll();
      })
    }
  }
}
</script>

<style scoped>

</style>