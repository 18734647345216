<template>
  <div>
    <div style="overflow: hidden;">
      <section>
        <!--工具条-->
        <el-col :span="24" class="flex-def flex-zCenter flex-cCenter" style="padding-bottom: 0;">
          <el-form :inline="true">
            <el-form-item>
              <el-input v-model="inputVal" placeholder="搜索城市名称点击地图拾取坐标" @keyup.native="inputChange" style="width: 20rem;"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">获取地理位置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </section>
    </div>
    <div class="mapWrap">
      <div class="qqmap" id="qqmapCont">
      </div>
      <div class="lngTips"></div>
    </div>

  </div>

</template>

<script>
let qq;
export default {
  props: {
    mapcenter: {
      type: String,
      default: '39.927378266437024,116.39019012451172'
    },
    oldmarker: {
      type: String,
      default: '39.927378266437024,116.39019012451172'
    },
    inputDefault: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      qqmap: '',
      premarker: '',
      marker: '',
      inputVal: '',
      latlngCurrent: ''
    }
  },
  mounted() {
    let that = this;
    this.inputVal = this.inputDefault;
    if (this.oldmarker) {
      this.latlngCurrent = this.oldmarker || this.mapcenter;
    }
    this.loadQQmap();
    window.onMapFileLoad = function () {
      qq = window.qq;
      that.createMap();
    }
  },
  watch: {
    oldmarker(newVal) {
      if (newVal) {
        this.latlngCurrent = newVal;
        this.qqmap && this.createMarker()
      }
    },
    inputDefault(newVal) {
      console.log('默认地址变成：', newVal)
      this.inputVal = newVal;
    }
  },
  methods: {
    loadQQmap() {
      let script = document.createElement("script");
      script.type = "text/javascript";
      let key = this.$store.state.user.info.uni_account.tx_map_key
      script.src = `https://map.qq.com/api/js?v=2.exp&key=${key}&callback=onMapFileLoad`;
      document.body.appendChild(script);
    },
    search() {
      let that = this;
      let geocoder = new qq.maps.Geocoder({
        complete: function (result) {
          that.qqmap.setCenter(result.detail.location);
          that.qqmap.setZoom(12)
        }
      });
      let address = this.inputVal;
      geocoder.getLocation(address);
    },
    inputChange() {
      this.$emit('addr', this.inputVal);
    },
    createMap() {
      let that = this;
      this.qqmap = new qq.maps.Map(document.getElementById("qqmapCont"), {
        center: new qq.maps.LatLng(that.mapcenter.split(',')[0], that.mapcenter.split(',')[1]),
        zoom: 12,
      });
      setTimeout(() => {
        this.createMarker();
        this.bindMapEvent()
      }, 500)
    },
    createMarker() {
      let that = this;
      if (that.premarker) {
        that.premarker.setMap(null);
      }
      if (this.oldmarker) {
        that.qqmap.setCenter(new qq.maps.LatLng(that.oldmarker.split(',')[0], that.oldmarker.split(',')[1]));
        that.premarker = new qq.maps.Marker({
          position: new qq.maps.LatLng(that.oldmarker.split(',')[0], that.oldmarker.split(',')[1]),
          map: that.qqmap
        });
      } else {
        let citylocation = new qq.maps.CityService({
          complete: function (result) {
            that.qqmap.setCenter(result.detail.latLng);
          }
        });
        citylocation.searchLocalCity();
      }
    },
    bindMapEvent() {
      let that = this;
      qq.maps.event.addListener(that.qqmap, 'click', function (event) {
        that.marker && that.marker.setMap(null);
        that.premarker && that.premarker.setMap(null);

        that.$emit('mapclick', event.latLng);
        that.latlngCurrent = event.latLng.lat + ',' + event.latLng.lng;
        that.marker = new qq.maps.Marker({
          position: event.latLng,
          map: that.qqmap
        });
      });
      let $lngTipsBox = document.querySelector('.lngTips');
      qq.maps.event.addListener(that.qqmap, 'mousemove', function (event) {
        $lngTipsBox.style.display = 'block';
        $lngTipsBox.style.top = (event.pixel.y + 10) + 'px';
        $lngTipsBox.style.left = (event.pixel.x + 15) + 'px';
        $lngTipsBox.innerText = '点击拾取坐标：' + event.latLng.lat + ',' + event.latLng.lng;
      });
      qq.maps.event.addListener(that.qqmap, 'mouseout', function () {
        $lngTipsBox.style.display = 'none';
      });
    },
  },
}
</script>

<style>
.qqmap {
  width: 100%;
  height: 30rem;
}

.mapWrap {
  position: relative;
  width: 100%;
  height: 30rem;
  overflow: hidden;
  margin-top: -15px;
}

.lngTips {
  display: none;
  width: 255px;
  height: 40px;
  padding: 5px 7px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 123456;
  background: #ffffff;
  border-radius: 5px;
  line-height: 20px;
  box-shadow: #eeeeee 1px 1px 3px;
  border: #eeeeee 1px solid;
  font-size: 12px;
}
</style>