<template>
  <div v-loading="loading">
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="奖励来源">
          <el-select v-model="searchForm.award_from">
            <el-option label="全部" :value="0"></el-option>
            <template v-if="active.mode !== 5">
              <el-option v-if="modeHasInvite" label="1级邀请订单" :value="1"></el-option>
              <el-option v-if="modeHasInvite2" label="2级邀请订单" :value="5"></el-option>
              <el-option label="转盘抽奖" :value="2"></el-option>
              <el-option label="分享红包" :value="3"></el-option>
              <el-option label="推荐礼品" :value="4"></el-option>
              <el-option label="员工服务奖励" :value="6"></el-option>
              <el-option label="九宫格抽奖" :value="7"></el-option>
              <el-option label="盲盒抽奖" :value="8"></el-option>
              <template v-if="classicSpecial">
                <el-option label="商家直推订单奖励" :value="9"></el-option>
                <el-option label="店员直推订单商家奖励" :value="10"></el-option>
                <el-option label="客户直推订单关联商家奖励" :value="11"></el-option>
                <el-option label="店员购买订单商家奖励" :value="12"></el-option>
                <el-option label="店员直推订单奖励" :value="13"></el-option>
                <el-option label="客户直推订单奖励-有关联商家" :value="14"></el-option>
                <el-option label="客户直推订单奖励-无关联商家" :value="15"></el-option>
              </template>
              <template v-if="groupBuySpecial">
                <el-option label="团长红包" :value="16"></el-option>
              </template>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="获奖时段">
          <el-date-picker
              v-model="searchForm.get_between"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="exp" type="primary" size="medium">导出</el-button>
      </div>

    </div>
    <el-table :data="list" :row-class-name="tableRowClassName" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="active.title" label="所属活动"></el-table-column>
      <el-table-column prop="active_reward.content" label="奖励内容"></el-table-column>
      <el-table-column prop="member.nickname" label="获利用户"></el-table-column>
      <el-table-column prop="active_reward.from" label="奖励来源"></el-table-column>
      <el-table-column prop="fail_log" label="异常原因"></el-table-column>
      <el-table-column label="获奖时间">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <el-table-column width="240" label="操作" fixed="right">
        <template #default="s">
          <el-button @click="handsPay(s.row.id)" size="mini">确认打款</el-button>
          <el-button @click="mergeSee(s.row.id)" size="mini">合并查看该用户</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog :title="`奖励补发合并查看共计${merge_fee}元`" destroy-on-close :visible.sync="mergeBox">
      <el-table :data="merge_list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="active.title" label="所属活动" show-overflow-tooltip></el-table-column>
        <el-table-column prop="active_reward.content" label="奖励内容" show-overflow-tooltip></el-table-column>
        <el-table-column prop="member.nickname" label="获利用户" show-overflow-tooltip></el-table-column>
        <el-table-column prop="active_reward.from" label="奖励来源" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fail_log" label="异常原因" show-overflow-tooltip></el-table-column>
        <el-table-column label="获奖时间" show-overflow-tooltip>
          <template #default="s">{{s.row.created_at | date}}</template>
        </el-table-column>
        <el-table-column width="110" label="操作">
          <template #default="s">
            <el-button @click="handsPay(s.row.id)" size="mini">确认打款</el-button>
          </template>
          <template #header>
            <el-popconfirm @confirm="mergePay" :title="`本次打款共${merge_list.length}笔，合计${merge_fee}元，\n请认真核对每笔佣金来源，如造成任何损失，后果自负！`">
              <el-button slot="reference" type="success" size="mini">合并打款</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="mergeBox = false">取 消</el-button>
        <el-button type="primary" @click="mergeBox = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "data_order",
  props:{
    active:{
      type:Object,
      default(){
        return{
          mode:0,
        }
      }
    },
  },
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        award_from:0,
        get_between:[],
      },
      mergeBox:false,
      merge_list:[],
      merge_fee : 0,
      loading:false,
    }
  },
  computed:{
    groupBuySpecial(){
      return this.active.mode == 4;
    },
    classicSpecial(){
      return this.active.mode == 3;
    },
    modeHasInvite(){
      return this.active.mode != 3;
    },
    modeHasInvite2(){
      return this.active.mode != 3;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    exp(){
      this.$api.active.recordAwardHandsExport({
        active_id: parseInt(this.$route.query.id),
      }).then(res=>{
        location.href = res;
      })
    },
    mergePay(){
      let loadTips = this.$loading({
        fullscreen:true,
        text:"执行中..."
      })
      let ids = [];
      this.merge_list.forEach(item=>{
        ids.push(item.id)
      })
      this.$api.active.recordAwardHandsMergePay({
        active_id:parseInt(this.$route.query.id),
        award_ids:ids
      }).then(()=>{
        loadTips.close();
        this.$message.success("操作成功");
        this.mergeBox = false;
        this.load();
      }).catch(()=>{
        loadTips.close();
        this.load();
      });
    },
    mergeSee(id){
      this.loading = true;
      this.$api.active.recordAwardHandsMergeSee({
        active_id:parseInt(this.$route.query.id),
        award_id:id
      }).then(({list,fee})=>{
        this.loading = false;
        this.merge_list = list;
        this.merge_fee = fee;
        this.mergeBox = true;
      });
    },
    handsPay(id){
      this.$api.active.recordAwardHandsPay({
        active_id:parseInt(this.$route.query.id),
        award_id:id
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      }).catch(()=>{
        this.load();
      });
    },
    resetSearch() {
      this.searchForm = {
        nickname: "",
        award_from: 0,
        get_between: [],
      }
      this.load();
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$api.active.recordAwardHands({
        active_id: parseInt(this.$route.query.id),
        nickname: this.searchForm.nickname,
        award_from: this.searchForm.award_from,
        get_between: this.searchForm.get_between,
        page: this.page
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>