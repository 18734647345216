<template>
  <div>
    <div v-if="active.mode != 6" style="margin-bottom: 1rem;line-height: 2rem;color: #909399">
      <div class="flex-def" style="color: #3e9afa">
        <div>总收入:<span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.total_order_fee }}</span>元</span></div>
        <div style="margin-left: 2rem">订单数: <span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.total_order }}</span>个</span></div>
        <div style="margin-left: 2rem">总支出: <span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.total_out }}</span>元</span></div>
      </div>
      <div class="flex-def" style="color: #3e9afa">
        <div>一级邀请奖励:<span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.invite }}</span>元</span></div>
        <div style="margin-left: 2rem">二级邀请奖励: <span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.invite_2 }}</span>元</span></div>
        <div style="margin-left: 2rem">员工红包: <span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.clerk }}</span>元</span></div>
        <div style="margin-left: 2rem">转盘、宫格、盲盒、抽奖红包: <span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.draw }}</span>元</span></div>
        <div style="margin-left: 2rem">分享红包: <span style="margin-left: .5rem;color:red;font-size: .8rem"><span
            style="font-size: 1.2rem;font-weight: 600">{{ order_desc.share }}</span>元</span></div>
      </div>
    </div>
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker
              v-model="searchForm.pay_between"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="searchForm.status">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="待支付" :value="1"></el-option>
            <el-option label="支付成功" :value="2"></el-option>
            <el-option label="支付失败" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <div>
        <el-popover
            placement="left"
            width="50"
            trigger="click">
          <div>
            <el-checkbox-group v-model="listField">
              <el-checkbox label="id">编号</el-checkbox>
              <el-checkbox label="member.avatar">头像</el-checkbox>
              <el-checkbox label="member.nickname">昵称</el-checkbox>
              <el-checkbox label="diy_form">表单信息</el-checkbox>
              <el-checkbox label="from_member">推荐用户</el-checkbox>
              <el-checkbox label="active.title">所属活动</el-checkbox>
              <template v-if="active.mode != 6">
                <el-checkbox label="status">支付状态</el-checkbox>
                <el-checkbox label="fee">支付金额</el-checkbox>
              </template>
              <template v-else>
                <el-checkbox label="cdkey">激活码</el-checkbox>
              </template>
              <el-checkbox label="order_no">订单编号</el-checkbox>
              <el-checkbox label="created_at">创建时间</el-checkbox>
              <el-checkbox label="pay_time">支付时间</el-checkbox>
            </el-checkbox-group>
          </div>
          <el-button slot="reference" icon="el-icon-menu" type="primary" size="medium"></el-button>
        </el-popover>
        <el-button @click="exp" type="primary" size="medium">导出</el-button>
      </div>

    </div>
    <el-table v-loading="loading" :data="list" :row-class-name="tableRowClassName" border style="width: 100%">
      <el-table-column v-if="listField.includes('id')" prop="id" label="编号" width="50"></el-table-column>
      <el-table-column v-if="listField.includes('member.avatar')" label="用户" width="200" show-overflow-tooltip
                       align="center">
        <template #default="s">
          <div v-if="s.row.member" class="flex-def flex-cCenter">
            <img style="width: 2rem;height: 2rem;border-radius: 3rem" :src="s.row.member.avatar | tomedia">
            <div style="margin-left: .5rem">{{ s.row.member.nickname }}</div>
          </div>
        </template>
      </el-table-column>
      <!--      <el-table-column v-if="listField.includes('member.nickname')" prop="member.nickname" label="昵称"-->
      <!--                       width="120"></el-table-column>-->
      <el-table-column v-if="listField.includes('diy_form')" label="姓名" width="100" show-overflow-tooltip>
        <template #default="scope">
          {{ diyFormShowName(scope.row.diy_form) }}
        </template>
      </el-table-column>
      <el-table-column v-if="listField.includes('diy_form')" label="手机" width="100" show-overflow-tooltip>
        <template #default="scope">
          {{ diyFormShowPhone(scope.row.diy_form) }}
        </template>
      </el-table-column>
      <el-table-column v-if="listField.includes('from_member')" label="推荐" width="160" show-overflow-tooltip>
        <template #default="s">
          <div v-if="s.row.from_member" class="flex-def flex-cCenter">
            <img style="width: 2rem;height: 2rem;border-radius: 3rem" :src="s.row.from_member.avatar | tomedia">
            <div style="margin-left: .5rem">{{ s.row.from_member.nickname }}</div>
          </div>
        </template>
      </el-table-column>
      <!--      <el-table-column v-if="listField.includes('from_member')" label="用户" width="120">-->
      <!--        <template #default="scope">-->
      <!--          <div v-if="scope.row.from_member"> {{ scope.row.from_member.nickname }}</div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column v-if="listField.includes('active.title')" prop="active.title" label="所属活动"-->
      <!--                       width="120"></el-table-column>-->
      <template v-if="active.mode != 6">
        <el-table-column v-if="listField.includes('status')" prop="status" label="支付状态" width="80">
          <template #default="s">
            <el-tag :type="s.row.status == 2 ? 'success' : 'danger'" effect="dark" size="small">
              {{ elPayStatus(s.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="listField.includes('fee')" prop="fee" label="支付金额" width="80">
          <template #default="s">
            <el-tag type="warning" effect="dark" size="small" style="width: 100%;text-align: center">{{ s.row.fee }}
            </el-tag>
          </template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column v-if="listField.includes('cdkey')" prop="cdkey" label="激活码" width="220"></el-table-column>
      </template>
      <el-table-column prop="agent_award_log.fee" label="代理佣金" width="80">
        <template #default="s">
          <el-tag v-if="s.row.agent_award_log" type="success" effect="dark" size="small"
                  style="width: 100%;text-align: center">{{ s.row.agent_award_log.fee }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="service_clerk" label="服务员工" width="160" show-overflow-tooltip>
        <template #default="s">
          <div v-if="s.row.service_clerk" class="flex-def flex-cCenter">
            <img style="width: 2rem;height: 2rem;border-radius: 3rem" :src="s.row.service_clerk.avatar | tomedia">
            <div style="margin-left: .5rem">{{ s.row.service_clerk.nickname }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="listField.includes('diy_form')" label="表单信息" show-overflow-tooltip>
        <template #default="scope">
          {{ diyFormShow(scope.row.diy_form) }}
        </template>
      </el-table-column>
      <el-table-column v-if="listField.includes('order_no')" prop="order_no" label="订单编号" width="220"
                       show-overflow-tooltip></el-table-column>
      <!--      <el-table-column v-if="listField.includes('created_at')" label="创建时间" width="180">-->
      <!--        <template #default="s">{{ s.row.created_at | date }}</template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column v-if="listField.includes('pay_time')" label="支付时间" width="180">-->
      <!--        <template #default="s">{{ s.row.pay_time | date }}</template>-->
      <!--      </el-table-column>-->
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="s">
          <el-button @click="detailThis(s.row)" type="success" size="small">详情</el-button>
          <el-button @click="delThis(s.row.id)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        :with-header="false"
        :before-close="drawerBeforeClose"
        size="30rem"
    >
      <div style="padding: 2rem">
        <div class="flex-def flex-cCenter flex-zBetween" style="width: 100%" v-if="drawer_order.member">
          <div class="flex-def flex-cCenter">
            <img style="width: 4rem;height: 4rem;border-radius: 4rem" :src="drawer_order.member.avatar | tomedia">
            <div style="margin-left: .5rem">
              <div style="color: red">¥<span style="font-size: 2rem;font-weight: 600">{{drawer_order.fee}}</span>元</div>
              <div style="font-size: 1rem">{{ drawer_order.member.nickname }}</div>
            </div>
          </div>
          <img v-if="drawer_order.status === 2" :src="paidPng" style="height: 4rem;width: 5rem">
          <img v-else :src="waitPng" style="height: 4rem;width: 4rem">
        </div>
        <div v-if="drawer_order.active" style="margin-top: .5rem;font-size: 1rem">{{ drawer_order.active.title }}</div>
        <div style="margin-top: .5rem">
          <div class="flex-def flex-cCenter flex-warp" style="border-bottom: 1px solid #ededed;border-right:1px solid #ededed">
            <div class="detail-item left tag">推荐人</div>
            <div class="flex-def flex-cCenter detail-item tag">
              <img v-if="drawer_order.from_member" style="width: 2rem;height: 2rem;border-radius: 4rem"
                   :src="drawer_order.from_member.avatar | tomedia">
              <span v-if="drawer_order.from_member" style="margin-left: .5rem">{{ drawer_order.from_member.nickname }}</span>
            </div>
            <div class="detail-item left">代理佣金</div>
            <div class="flex-def flex-cCenter detail-item">
              <span v-if="drawer_order.agent_award_log" style="margin-left: .5rem;color: red">
                <span style="font-size: 1.2rem;font-weight: 600">{{ drawer_order.agent_award_log.fee  }}</span>元
              </span>
            </div>
            <div class="detail-item left tag">服务员工</div>
            <div class="flex-def flex-cCenter detail-item tag">
              <span v-if="drawer_order.service_clerk_award" style="margin-left: .5rem;color: red"><span style="font-size: 1.2rem;font-weight: 600">{{ drawer_order.service_clerk_award.fee }}</span>元</span>
            </div>
            <div class="detail-item left">订单ID</div>
            <div class="flex-def flex-cCenter detail-item">
              <span style="margin-left: .5rem;">{{drawer_order.id}}</span>
            </div>
            <div class="detail-item left tag">订单编号</div>
            <div class="flex-def flex-cCenter detail-item tag">
              <span style="margin-left: .5rem;">{{drawer_order.order_no}}</span>
            </div>
            <div class="detail-item left">支付单号</div>
            <div class="flex-def flex-cCenter detail-item">
              <span style="margin-left: .5rem;">{{ drawer_order.payment_order_no }}</span>
            </div>
            <div class="detail-item left tag">下单时间</div>
            <div class="flex-def flex-cCenter detail-item tag">
              <span style="margin-left: .5rem;">{{drawer_order.created_at | date}}</span>
            </div>
            <div class="detail-item left">支付时间</div>
            <div class="flex-def flex-cCenter detail-item">
              <span v-if="drawer_order.status === 2" style="margin-left: .5rem;">{{drawer_order.pay_time | date}}</span>
            </div>
            <div class="detail-item tag" style="width: 100%;font-size: 1.4rem;font-weight: 600">表单信息</div>
            <template v-for="(item,key) in diyFormParse(drawer_order.diy_form)" >
              <div class="detail-item left" :key="key">{{ item.title }}</div>
              <div class="flex-def flex-cCenter detail-item" :key="'i' + key">
                <span style="margin-left: .5rem;">{{ item.value }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "data_order",
  props: {
    active: {
      type: Object,
      default() {
        return {
          mode: 0,
        }
      }
    },
  },
  computed: {
    paidPng() {
      return `./${process.env.BASE_URL}icon/paid.png`;
    },
    waitPng() {
      return `./${process.env.BASE_URL}icon/wait.png`;
    }
  },
  data() {
    return {
      drawer: false,
      drawer_order: {
        active: {},
        member: {},
        from_member: {},
        agent_award_log:{},
        service_clerk_award:{},
      },
      searchForm: {
        nickname: "",
        status: 0,
        pay_between: []
      },
      page: 1,
      total: 0,
      list: [],
      loading: false,
      listField: [
        "id",
        "member.avatar",
        "member.nickname",
        "diy_form",
        "from_member",
        "active.title",
        "status",
        "fee",
        "cdkey",
        "order_no",
        "created_at",
        "pay_time"
      ],
      order_desc: {
        total_order: 0,
        total_order_fee: 0,
        total_out: 0,
        invite: 0,
        invite_2: 0,
        clerk: 0,
        draw: 0,
        share: 0,
      }
    }
  },
  mounted() {
    this.load();
    this.$api.active.recordOrderDesc({
      active_id: parseInt(this.$route.query.id),
    }).then(res => {
      this.order_desc = res;
    })
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    drawerBeforeClose(done) {
      this.drawer_order = {
        active: {},
        member: {},
        from_member: {},
      }
      done();

    },
    detailThis(item) {
      this.drawer_order = {...item};
      this.drawer = true;
    },
    delThis(id) {
      this.$api.active.recordOrderDel({
        active_id: parseInt(this.$route.query.id),
        id: id
      }).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    diyFormParse(el){
      if (!el)return [];
      return JSON.parse(el);
    },
    diyFormShowName(el) {
      if (!el) return "";
      let list = JSON.parse(el);
      let item = list.filter((e) => e.title === "姓名");
      if (!item) return "";
      return item.pop()?.value;
    },
    diyFormShowPhone(el) {
      if (!el) return "";
      let list = JSON.parse(el);
      let item = list.filter((e) => e.title === "手机")
      if (!item) return "";
      return item.pop()?.value;
    },
    diyFormShow(el) {
      if (!el) return "";
      let item = JSON.parse(el);
      let str = "";
      item.forEach(v => {
        str += `[${v.title}：【${v.value}】];  `;
      })
      return str;
    },
    exp() {
      this.$api.active.recordOrderExport({
        active_id: parseInt(this.$route.query.id),
        list_field: this.listField,
        nickname: this.searchForm.nickname,
        status: this.searchForm.status,
        pay_between: this.searchForm.pay_between,
      }).then(res => {
        location.href = res;
      })
    },
    elPayStatus(status) {
      let str = "";
      switch (parseInt(status)) {
        case 1:
          str = "未支付";
          break;
        case 2:
          str = "已支付";
          break
      }
      return str;
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    resetSearch() {
      this.searchForm = {
        nickname: "",
        status: 0,
        pay_between: []
      }
      this.load();
    },
    load() {
      this.loading = true;
      this.$api.active.recordOrder({
        active_id: parseInt(this.$route.query.id),
        nickname: this.searchForm.nickname,
        status: this.searchForm.status,
        pay_between: this.searchForm.pay_between,
        page: this.page
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style>
.el-table__row div.cell {
  white-space: nowrap;
}

.detail-item {
  padding: .5rem;
  border-left: 1px solid #ededed;
  border-top: 1px solid #ededed;
  width: calc(100% - 8.2rem);
  height: 2rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.detail-item.tag{
  background-color: #f8f8f8;
}
.detail-item.left{
  width: 6rem;
}
.detail-item{

}
</style>